<app-logos class="full-frame-fixed"></app-logos>

<div class="content">

  <div class="left">
    <h1>Welkom.</h1>
  </div>


  <div class="box right" *ngIf="!this.codeIsExpired">
    <div class="sidebar-gradient"></div>
    <div class="sidebar-corner"></div>

    <div class="inner">
      <h1><b>PontMeyer</b><br />
        Brick Configurator.</h1>
      <p class="title">Log hier in met uw unieke klantcode en begin met samenstellen!</p>

      <div class="flex"></div>
      <input type="text" [(ngModel)]="code" placeholder="Voer hier uw unieke code in">
      <span class="error-message" [ngClass]="{ 'invisible': this.codeIsValid}">*code incorrect</span>
      <div class="flex"></div>
      <a class="button red rounded" (click)="clicked()">Doorgaan</a>
    </div>

    <div class="box" *ngIf="this.codeIsExpired">
      <span class="error-message">De door u opgegeven code is verlopen. U kunt een verlenging van de code
        aanvragen via de onderstaande knop.</span>
      <div>
        <div class="flex"></div>
        <a class="button white-outline rounded" (click)="this.codeIsExpired = false">Terug </a>
        <a class="button red rounded" (click)="requestCodeExtension()">Verlenging aanvragen
        </a>
      </div>
    </div>
  </div>
</div>