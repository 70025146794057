import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss']
})
export class LogosComponent implements OnInit {

  @Input("left") left: boolean = true;
  @Input("right") right: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
