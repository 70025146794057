import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Postdata } from 'src/app/models/postdata';
import { ReturnData } from 'src/app/models/returndata';
import { CodeService } from 'src/app/services/code.service';
import { OrderService } from 'src/app/services/order.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private stateService: CodeService,
    private router: Router,
    private orderService: OrderService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.stateService.clearState();
    this.orderService.resetSave();
    // this.dialog.show('Test', 'ok', 'cancel');
  }

  code: string = '';
  codeIsValid: boolean = true;
  codeIsExpired: boolean = false;

  clicked() {
    const _code = this.code.trim();
    this.codeIsValid = true;

    let postdata = new Postdata<string>();
    postdata.data = _code;

    let form = new FormData();
    form.append('data', JSON.stringify(postdata));

    this.http
      .post<ReturnData<string>>('./api/validate-code/', form)
      .subscribe((result) => {
        if (result.data == 'VALID') {
          this.stateService.markCodeAsValid(_code);
          this.router.navigate(['/', 'config']);
        }

        if (result.data == 'EXPIRED') {
          this.codeIsExpired = true;
        }

        if (result.errorMessage == 'NOTFOUND') {
          this.codeIsValid = false;
        }
      });
  }

  requestCodeExtension() {
    const _code = this.code;

    let postdata = new Postdata<string>();
    postdata.data = _code;

    let form = new FormData();
    form.append('data', JSON.stringify(postdata));

    this.http
      .post<ReturnData<string>>('./api/request-code-extension/', form)
      .subscribe((result) => {
        if (result.success) {
          this.code = '';
          this.codeIsExpired = false;
          this.codeIsValid = true;

          this.dialog.show(
            'Uw verlenging is aangevraagd en zullen wij in behandeling nemen. Per mail zullen wij u op de hoogte stellen van de uitkomst.',
            'sluiten'
          );
        }
      });
  }
}
