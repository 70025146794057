import { EventEmitter, Injectable } from '@angular/core';

export enum Display {
  Undefined = -1,
  XSmall,
  Small,
  Medium,
  Large,
  XLarge
}

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  private media: Display = Display.Undefined;
  public getMedia(): Display {
    return this.media;
  }

  public changed: EventEmitter<Display> = new EventEmitter<Display>();

  constructor() { }

  readonly XSmall = 'screen and (max-width: 599px) and (orientation: portrait)';
  readonly XSmall2 = 'screen and (max-height: 599px) and (orientation: landscape) and (hover: none)';

  readonly Small = 'screen and (min-width: 600px) and (max-width: 959px) and (orientation: portrait)';
  readonly Small2 = 'screen and (max-height: 959px) and (orientation: landscape) and (hover: none)';

  readonly Medium = 'screen and (min-width: 960px) and (max-width: 1439px)';
  readonly Large = 'screen and (min-width: 1440px) and (max-width: 1919px)';
  readonly XLarge = 'screen and (min-width: 1920px)';

  public init() {

    window.matchMedia(this.XSmall).addEventListener("change", (ev) => { this.handleChange(ev) });
    window.matchMedia(this.XSmall2).addEventListener("change", (ev) => { this.handleChange(ev) });
    window.matchMedia(this.Small).addEventListener("change", (ev) => { this.handleChange(ev) });
    window.matchMedia(this.Small2).addEventListener("change", (ev) => { this.handleChange(ev) });
    window.matchMedia(this.Medium).addEventListener("change", (ev) => { this.handleChange(ev) });
    window.matchMedia(this.Large).addEventListener("change", (ev) => { this.handleChange(ev) });
    window.matchMedia(this.XLarge).addEventListener("change", (ev) => { this.handleChange(ev) });

    console.log("subscribed to media query changes");

    //1 time manual check to init this
    if (window.matchMedia(this.XSmall).matches)
      this.applyQuery(this.XSmall);

    if (window.matchMedia(this.XSmall2).matches)
      this.applyQuery(this.XSmall2);

    if (window.matchMedia(this.Small).matches)
      this.applyQuery(this.Small);

    if (window.matchMedia(this.Small2).matches)
      this.applyQuery(this.Small2);

    if (window.matchMedia(this.Medium).matches)
      this.applyQuery(this.Medium);

    if (window.matchMedia(this.Large).matches)
      this.applyQuery(this.Large);

    if (window.matchMedia(this.XLarge).matches)
      this.applyQuery(this.XLarge);
  }

  handleChange(ev: MediaQueryListEvent) {
    if (ev.matches) {
      this.applyQuery(ev.media);
    }
  }

  applyQuery(media: string) {
    // console.log("apply media query " + media);
    media = media.trim();
    switch (media) {
      case this.XSmall:
      case this.XSmall2:
        this.media = Display.XSmall;
        console.log("apply media query XSmall");
        break;
      case this.Small:
      case this.Small2:
        this.media = Display.Small;
        console.log("apply media query Small");
        break;
      case this.Medium:
        this.media = Display.Medium;
        console.log("apply media query Medium");
        break;
      case this.Large:
        this.media = Display.Large;
        console.log("apply media query Large");
        break;
      case this.XLarge:
        this.media = Display.XLarge;
        console.log("apply media query XLarge");
        break;
      default:
        console.log("media query unknown: " + media);
        // console.log("XSmall" + this.XSmall);
        // console.log("XSmall2" + this.XSmall2);
        // console.log("Small2" + this.Small2);
        // console.log("Medium" + this.Medium);
        // console.log("Large" + this.Large);
        // console.log("XLarge" + this.XLarge);
        this.media = Display.Medium;
    }
    this.changed.emit(this.media);
  }
}
