import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Helpers } from '../models/helpers';
import { BrickLibrary, Steen } from '../models/library';
import { Verband, Voegkleur } from '../models/order';
import { ReturnData } from '../models/returndata';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  private library?: BrickLibrary = undefined;

  public gotLibrary: ReplaySubject<BrickLibrary> = new ReplaySubject();

  constructor(private http: HttpClient) { }

  public init() {

    this.http.get<ReturnData<BrickLibrary>>("./api/get-brick-library").subscribe((result) => {
      this.library = new BrickLibrary();
      Object.assign(this.library, result.data);
      // this.library = result.data;
      // console.log("library");
      // console.log(this.library);

      this.gotLibrary.next(this.library);
    });

  }

  public getBrick(code: string): Steen | undefined {
    if (this.library == undefined)
      return undefined;

    const el = this.library.stenen.find(x => x.Code == code);
    return el;
  }


  public getBrickLabel(code: string, verband: Verband, withFormatting: boolean = false): string {
    const brick = this.getBrick(code);
    let html = "";

    if (brick != undefined) {

      if (!withFormatting) {
        html += brick.Code;

      } else {

        if (brick.IsProjectsteen) {
          html += `<b style="color:red;">${brick.Code}</b>`;
        }
        else {
          html += `<b>${brick.Code}</b>`;
        }

      }
      return html + ` ${brick.Kleur} ${Helpers.SteenvormShort(brick.Steenvorm)} ${Helpers.SteenformaatShort(brick.Steenformaat)}`;
    }

    return "";
  }

  public getVoegLabel(voeg: Voegkleur): string {
    if (this.library == undefined)
      return "";

    const v = this.library.voegen.find(x => x.enum_value == voeg);

    if (v != undefined)
      return v.label;

    return "";
  }

  public getVoegLabelWithID(voeg: Voegkleur): string {
    if (this.library == undefined)
      return "";

    const v = this.library.voegen.find(x => x.enum_value == voeg);

    if (v != undefined)
      return v.label + " " + v.id;

    return "";
  }

  public getVerbandLabel(verband: Verband): string {
    if (this.library == undefined)
      return "";

    const v = this.library.verbanden.find(x => x.enum_value == verband);

    if (v != undefined)
      return v.label;

    return "";
  }

  public getRooftileLabel(code: string, bolded: boolean): string {
    if (this.library == undefined)
      return "";
    const d = this.library.dakpannen.find(x => x.Code == code);

    if (d != undefined) {
      return bolded ? `<b>${d.Label}</b>` : d.Label;
    }

    return "";
  }

  public getRooftileDescription(code: string): string {
    if (this.library == undefined)
      return "";
    const d = this.library.dakpannen.find(x => x.Code == code);

    if (d != undefined) {
      //there are breaklines in the description, and the last line has padded spaces for fake "start after * "
      //replace spaces with &nbsp; because HTML wil ignore more than 1 space
      //replace breaklines with corresponding HTML element
      const replaced = d.Omschrijving
        .replace(new RegExp(" ", "g"), "&nbsp;")
        .replace(new RegExp("\n", "g"), "<br />")
        .split("\\n").join("<br />");

      return replaced;
    }

    return "";
  }
}
