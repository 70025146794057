import { Component, OnInit } from '@angular/core';
import { LibraryService } from 'src/app/services/library.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-configbase',
  templateUrl: './configbase.component.html',
  styleUrls: ['./configbase.component.scss']
})
export class ConfigbaseComponent implements OnInit {

  constructor(private libService: LibraryService, private orderService: OrderService) {
    libService.init();
    orderService.init();
  }

  ngOnInit(): void {
  }

}
